.content {
  padding-top: 64px;
  padding-bottom: 50px;
  color: white;
}

.header {
  margin-bottom: 20px;
}

.topStyleItem {
  background: #6b46c1;
  color: white;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.titleSection {
  margin-top: 30px;
  margin-bottom: 40px;

  > h2 {
    font-family:
      Crimson Text,
      Crimson Text;
    font-weight: normal;
    font-size: 68px;
    line-height: 1;
    color: #ffffff;
    line-height: 82px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    font-family: CrimsonText-Italic;
  }

  > p {
    margin-top: 20px;
    font-family: Inter, Inter;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    line-height: 25px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  @media screen and (max-width: 900px) {
    > h2 {
      font-size: 58px;
      line-height: 1;
    }
  }

  @media screen and (max-width: 768px) {
    > h2 {
      font-size: 48px;
      line-height: 1;
    }
  }

  @media screen and (max-width: 576px) {
    > h2 {
      font-size: 32px;
      line-height: 1;
    }

    > p {
      font-size: 16px;
    }
  }
}

.hairstyleGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  margin-bottom: 40px;

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.hairstyleCard {
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .imageContainer {
    position: relative;
    aspect-ratio: 224/346;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;

    > .img {
      border-radius: 12px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .editBadge {
      background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
      border-bottom-left-radius: 11px;
      border-bottom-right-radius: 11px;
      padding: 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      font-size: 12px;

      display: inline-flex;
      align-items: center;
      gap: 4px;
    }

    .innerContent {
      z-index: 1;

      padding: 0 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      text-align: center;
    }

    > .tryOnContainer {
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;

      > span {
        background: var(--app-primary-color);
        color: #fff;
        padding: 5px;
        border-radius: 8px;
        min-width: 80%;
        display: inline-block;
        text-align: center;
        cursor: pointer;
      }

      display: none;
    }

    > .topRightContainer {
      position: absolute;
      top: 10px;
      right: 10px;

      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: grid;
      place-items: center;
      background: #fff;
      color: var(--other-text-color);
      cursor: pointer;

      display: none;
    }

    &:hover {
      > .tryOnContainer {
        display: flex;
      }

      .topRightContainer {
        display: grid;
      }

      .editBadge {
        display: none;
      }
    }
  }

  .tags {
    padding: 12px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

    .tag {
      color: #fff;
      padding: 2px 6px;
      border-radius: 5px;
      font-size: 12px;
      border: 1px solid red;
      cursor: pointer;
    }
  }
}

.exploreMore {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(107, 70, 193, 0.1);
  border-radius: 12px;
  padding: 24px;
  text-align: center;

  h3 {
    color: #6b46c1;
    font-size: 18px;
  }
}

.exploreContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .exploreButton {
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    background: #9873ff;
    text-align: center;
    color: #fff;
    cursor: pointer;
    padding: 8px 24px;
    border-radius: 18px;
  }
}

